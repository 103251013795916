import React from 'react'

import Layout from '../../components/Layout'

function StructureCatchAll ({ params }) {
  return (
    <Layout title='Missing Structure'>
      <h1>Structure not found!</h1>
      <p>We couldn&apos;t locate the structure &apos;{params.uid}&apos;</p>
    </Layout>
  )
}

export default StructureCatchAll
